<template>
  <el-select
    v-model="localValue"
    :size="size"
    :multiple="multiple"
    remote
    clearable
    filterable
    :placeholder="placeholder"
    :remote-method="remoteMethod"

    @visible-change="$event ? remoteMethod() : null"
  >
    <div
      v-loading="loading"
      v-infinite-scroll="nextPage"
      :infinite-scroll-immediate="false"
      :infinite-scroll-delay="100"
    >
      <el-option
        v-for="item in options"
        :key="item.id"
        :label="item.full_name"
        :value="item.id"
      >
      </el-option>
    </div>
  </el-select>

</template>

<script>


import {paginator} from "@/mixins/paginator";
import requestSender from "@/api/base/requestSender";

export default {
  name: "smz-user-in-procedure-select",
  mixins: [paginator],
  components: {},
  props: {
    value: {},
    size: {type: String, default: ''},
    placeholder: {type: String, default: ''},
    multiple: {type: Boolean, default: true},
    filters: {type: Object},
  },

  data() {
    return {
      loading: false,
      query: null,
      page: 1,
      options: [],
    }
  },
  mounted() {
    this.remoteMethod()
  },
  watch: {},
  computed: {
    localValue: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        if( !newValue ){
          newValue = null;
        }
        this.selected(newValue)
      }
    }
  },
  methods: {
    remoteMethod(query) {
      this.query = query;
      this.page = 1;
      this.options = [];
      this.getData();
    },

    nextPage(){
      this.page++;
      this.getData();
    },

    getData() {
      if( this.paginator.pageCount && this.page > this.paginator.pageCount ){
        return;
      }
      this.loading = true;
      const filterRole = this.filterRole ? this.filterRole : this.$companyConfiguration.user.getUsersListDefaultRoleFilterInSearch();
      requestSender('get', 'compensation-procedure/search-users-in-procedure', {
        search: this.query,
        page: this.page,
        owner_id: this.$route.params.owner_id,
        procedure_id: this.$route.params.procedure_id,
      })
        .then(({data, paginator}) => {
          this.options = this.options.concat(data.users);
          this.paginatorFill(paginator);
        })
        .finally(() => {
          this.loading = false;
        })
    },

    selected(value) {
      this.$emit('input', value);
      this.$emit('selected', value)
    }
  },

}
</script>


<style scoped>

</style>